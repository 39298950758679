import React from 'react';


//project styling here:
const Project = ({ item, text }) => {
  return (
    <div key={item.id} className='flex flex-col items-center text-center'>
      <div className='mb-8'>
        <img className='rounded-2xl' src={item.image} alt='' />
      </div>
      <p className='text-base max-w-md'>
        <h3 className='text-2xl font-semibold capitalize mb-3'>{item.name}</h3>
        <p className='text-accent text-sm mb-3'>{item.category}</p>

      </p>
    </div>
  );
};

export default Project;
