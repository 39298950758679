import React, { useState, useEffect } from 'react';
import Project from './Project';
import { projectsData } from '../data';


const ProjectLayout = () => {
  const [item] = useState({ name: 'all' });
  const [projects, setProjects] = useState([]);


  useEffect(() => {

    if (item.name === 'all') {
      setProjects(projectsData);
    } else {
      const newProjects = projectsData.filter((project) => {
        return project.category.toLowerCase() === item.name;
      });
      setProjects(newProjects);
    }
  }, [item]);

  return (
    <div>
      <nav className='mb-12 max-w-xl mx-auto'>
        <ul className='flex flex-col md:flex-row justify-evenly items-center text-white'>
        </ul>
      </nav>

      <section className='grid gap-y-12 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-8'>
        
        {projects.map((item) => {
          return <Project item={item} key={item.id} />;
        })}
      </section>
    </div>
  );
};

export default ProjectLayout;
