import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { ArrowCircleUpIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';

const BackToTop = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      return window.scrollY > 2000 ? setShow(true) : setShow(false);
    });
  });
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  
    return (
      show && (
        <motion.button
          onClick={() => scrollToTop()}
          className='bg-accent hover:bg-accent-hover text-black w-10 h-10 rounded-full fixed right-24 bottom-24 cursor-pointer flex justify-center items-center transition-all'
          whileHover={{
            scale:1.3,
            boxShadow: "0px 0px 5px rgb(255, 255, 255)",
          }}
          transition={{type: 'spring', stifness: 80}}>
        
          <ArrowCircleUpIcon className='w-10 h-15' />
        </motion.button>
      )
    );
  
};

export default BackToTop;
