import React from 'react';
import { social } from '../data';
import { motion } from 'framer-motion';



const SocialIcons = () => {
  return (
    <ul className='flex space-x-6 '>
      {social.map((item, index) => {
        return (
          <li
            className='flex justify-center items-center socials'
            key={index}
          >
            <a className='text-base' href={item.href}>
            
            <motion.div type="icon" className=''
                  whileHover={{
                    scale: 2,
                    
                  }}
                  animate={{ scale: 1.4}}
                  
                  transition={{ type: 'spring', stifness: 50, mass: 2.8, damping: 10, ease: "easeOut", duration: 1 }}>
                  {item.icon}
            </motion.div>

            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default SocialIcons;
